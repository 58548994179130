import * as React from "react"

type Props = {
    noText?: boolean
}

const Logo = ({noText}: Props) => (
    <svg
        width={130}
        height={21}
        viewBox="0 0 220.48571477617537 36.00000000000001"
        className="css-1j8o68f"
    >
        <defs id="SvgjsDefs2129" />
        <g
            id="SvgjsG2130"
            transform="matrix(1.2857141981319566,0,0,1.2857141981319566,-5.142856792527827,-15.428567925278262)"
            fill="#ffffff"
        >
            <path d="M7 40 l-3 0 l0 -28 l12.4 0 c4.2 0 7 2.8 7 6.6 l0 5.4 c0 3.8 -2.8 6.6 -7 6.6 l-9.4 0 l0 9.4 z M16.4 14.600000000000001 l-9.4 0 l0 13.4 l9.4 0 c2.2 0 4 -1.8 4 -4 l0 -5.4 c0 -2.2 -1.8 -4 -4 -4 z M32.400000000000006 40 l-3 0 l0 -28 l12.4 0 c4.2 0 7 2.8 7 6.6 l0 3.8 c0 5.6 -5.4 6.4 -5.4 6.4 l6.2 10 l0 1.2 l-3 0 l-6.2 -11 l-8 0 l0 11 z M41.8 14.600000000000001 l-9.4 0 l0 11.8 l9.4 0 c2.2 0 4 -1.8 4 -4 l0 -3.8 c0 -2.2 -1.8 -4 -4 -4 z M58.6 40 l-3 0 l0 -28 l3 0 l0 28 z M64.8 19.8 l0 -1.2 c0 -3.8 2.8 -6.6 7 -6.6 l11 0 l0 1.8 l-0.8 0.8 l-10.2 0 c-2.2 0 -4 1.8 -4 4 l0 1.2 c0 2.2 1.2 3.36 3.2 3.8 l7.4 1.6 c2.8 0.6 5 2.8 5 6.4 l0 1.8 c0 3.8 -2.8 6.6 -7 6.6 l-12 0 l0 -2.6 l12 0 c2.2 0 4 -1.8 4 -4 l0 -1.8 c0 -2.2 -1.2 -3.36 -3.2 -3.8 l-7.4 -1.6 c-2.8 -0.6 -5 -2.8 -5 -6.4 z  M104.80000000000001 36.2 l-3 0 l-8.2 -19.6 l-0.2 0 l-2.8 23.4 l-3 0 l0 -1.2 l3.6 -26.8 l3.6 0 l8.4 20.2 l0.2 0 l8.4 -20.2 l3.6 0 l3.6 26.8 l0 1.2 l-3 0 l-2.8 -23.4 l-0.2 0 z" />
        </g>
        <g
            id="SvgjsG2131"
            transform="matrix(0.45,0,0,0.45,148.5,-4.5)"
            fill="#ffffff"
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M50,10L10,90h80L50,10z M50,24.909L72.546,70H42.36l3.333-6.667h16.064L50,39.814L31.575,76.667h44.304l3.333,6.666H20.788  L50,24.909z"
            />
        </g>
        <g
            id="SvgjsG2132"
            transform="matrix(1.2857142857142858,0,0,1.2857142857142858,193.48571477617537,-15.42857142857143)"
            fill="#ffffff"
        >
            <path d="M12.2 14.600000000000001 l0 25.4 l-3 0 l0 -25.4 l-8.8 0 l0 -2.6 l20.6 0 l0 1.8 l-0.8 0.8 l-8 0 z" />
        </g>
    </svg>
)

export default Logo;
